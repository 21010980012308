@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Medium'), local('BloggerSans-Medium'), url('./App/assets/fonts/500.woff2') format('woff2'), url('./App/assets/fonts/500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Medium Italic'), local('BloggerSans-MediumItalic'), url('./App/assets/fonts/500i.woff2') format('woff2'), url('./App/assets/fonts/500i.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Italic'), local('BloggerSans-Italic'), url('./App/assets/fonts/400i.woff2') format('woff2'), url('./App/assets/fonts/400i.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Light'), local('BloggerSans-Light'), url('./App/assets/fonts/300.woff2') format('woff2'), url('./App/assets/fonts/300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('./App/assets/fonts/400.woff2') format('woff2'), url('./App/assets/fonts/400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Bold Italic'), local('BloggerSans-BoldItalic'), url('./App/assets/fonts/700i.woff2') format('woff2'), url('./App/assets/fonts/700i.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Bold'), local('BloggerSans-Bold'), url('./App/assets/fonts/700.woff2') format('woff2'), url('./App/assets/fonts/700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Light Italic'), local('BloggerSans-LightItalic'), url('./App/assets/fonts/300i.woff2') format('woff2'), url('./App/assets/fonts/300i.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 500 600;
    src: url('./App/assets/fonts/als-sirius/ALSSirius-Medium.woff2') format("woff2"), url('./App/assets/fonts/als-sirius/ALSSirius-Medium.woff') format("woff")
}

@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 400;
    src: url('./App/assets/fonts/als-sirius/ALSSirius-Regular.woff2') format("woff2"), url('./App/assets/fonts/als-sirius/ALSSirius-Regular.woff') format("woff")
}

@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 300;
    src: url('./App/assets/fonts/als-sirius/ALSSirius-Light.woff2') format("woff2"), url('./App/assets/fonts/als-sirius/ALSSirius-Light.woff') format("woff")
}

body {
    margin: 0;
    padding: 0;
    font-family: "Blogger Sans", Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4)
    }
    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
